<template>
  <svg
    width="426"
    height="420"
    viewBox="0 0 426 420"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.991"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M88.5 -0.5C89.5 -0.5 90.5 -0.5 91.5 -0.5C121.296 31.1309 151.296 62.6309 181.5 94C160.503 94.5 139.503 94.6666 118.5 94.5C101.163 94.3334 83.8301 94.5001 66.5 95C84.0042 111.005 101.338 127.172 118.5 143.5C118.5 235.5 118.5 327.5 118.5 419.5C99.8333 419.5 81.1667 419.5 62.5 419.5C62.5 311.167 62.5 202.833 62.5 94.5C41.5 94.5 20.5 94.5 -0.5 94.5C-0.5 94.1667 -0.5 93.8333 -0.5 93.5C29.62 62.5909 59.2867 31.2576 88.5 -0.5Z"
      fill="#FCC300"
    />
    <path
      opacity="0.988"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M332.5 -0.5C333.5 -0.5 334.5 -0.5 335.5 -0.5C365.478 31.1462 395.478 62.8129 425.5 94.5C404.511 95.4997 383.511 95.833 362.5 95.5C345.338 94.3382 328.005 94.1715 310.5 95C311.044 95.7172 311.711 96.2172 312.5 96.5C329.14 112.139 345.807 127.805 362.5 143.5C362.5 235.5 362.5 327.5 362.5 419.5C343.833 419.5 325.167 419.5 306.5 419.5C306.5 311.5 306.5 203.5 306.5 95.5C285.489 95.833 264.489 95.4997 243.5 94.5C272.817 63.3486 302.317 32.3486 332 1.5C332.464 0.905578 332.631 0.238911 332.5 -0.5Z"
      fill="#4ACE27"
    />
    <path
      opacity="0.99"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M118.5 94.5C118.5 110.833 118.5 127.167 118.5 143.5C101.338 127.172 84.0042 111.005 66.5 95C83.8301 94.5001 101.163 94.3334 118.5 94.5Z"
      fill="#DAA900"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M362.5 95.5C345.658 95.1702 328.992 95.5035 312.5 96.5C311.711 96.2172 311.044 95.7172 310.5 95C328.005 94.1715 345.338 94.3382 362.5 95.5Z"
      fill="#4EBA3D"
    />
    <path
      opacity="0.99"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M362.5 95.5C362.5 111.5 362.5 127.5 362.5 143.5C345.807 127.805 329.14 112.139 312.5 96.5C328.992 95.5035 345.658 95.1702 362.5 95.5Z"
      fill="#50B345"
    />
    <path
      opacity="0.987"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M244.5 228.5C223.831 228.333 203.164 228.5 182.5 229C203.043 246.753 223.71 264.253 244.5 281.5C244.5 327.5 244.5 373.5 244.5 419.5C223.167 419.5 201.833 419.5 180.5 419.5C180.5 355.833 180.5 292.167 180.5 228.5C161.164 228.667 141.83 228.5 122.5 228C152.958 196.22 183.291 164.387 213.5 132.5C244.321 164.155 274.988 195.988 305.5 228C285.169 228.5 264.836 228.667 244.5 228.5Z"
      fill="#2795FA"
    />
    <path
      opacity="0.984"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M244.5 228.5C244.5 246.167 244.5 263.833 244.5 281.5C223.71 264.253 203.043 246.753 182.5 229C203.164 228.5 223.831 228.333 244.5 228.5Z"
      fill="#1F81DB"
    />
  </svg>
</template>

<script>
export default {
  name: 'GoHighLevel',
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
}
</script>
