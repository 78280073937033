import { getLSItem } from '@/utils/localStorage'

const Calls = () =>
  import(/* webpackChunkName: 'Calls' */ '@/views/calls/Calls.vue')

const CallSummary = () =>
  import(/* webpackChunkName: 'CallSummary' */ '../views/calls/CallSummary')

const UserCallSummary = () =>
  import(
    /* webpackChunkName: 'UserCallSummary' */ '../views/calls/UserCallSummary'
  )
const CallDetails = () =>
  import(/* webpackChunkName: 'CallDetails' */ '../views/calls/CallDetails')

const LiveCalls = () =>
  import(/* webpackChunkName: 'LiveCalls' */ '../views/calls/LiveCalls')

const PDSessionsContainer = () =>
  import(
    /* webpackChunkName: 'PDSessionsContainer' */ '../views/calls/PowerDialing/PDSessionsContainer.vue'
  )

const ActivePDSessions = () =>
  import(
    /* webpackChunkName: 'ActivePDSessions' */ '../views/calls/PowerDialing/ActivePDSessions'
  )

const CompletedPDSessions = () =>
  import(
    /* webpackChunkName: 'CompletedPDSessions' */ '../views/calls/PowerDialing/CompletedPDSessions'
  )

const isLiteMode = getLSItem('lite') || false

const callsRoutes = {
  component: Calls,
  props: true,
  path: '/calls',
  meta: {
    requiresAuth: true,
    showNav: true,
    navTitle: 'Calls',
  },
  children: [
    ...(isLiteMode
      ? [
          {
            path: '',
            redirect: 'power-dialing',
          },
          {
            path: 'power-dialing',
            name: 'PDSessionsContainer',
            component: PDSessionsContainer,
            meta: {
              requiresAuth: true,
              showNav: true,
              navTitle: 'Power Dialing',
              hasSubMenu: true,
              scrollView: false,
            },
          },
        ]
      : [
          {
            path: '',
            redirect: 'power-dialing/active',
          },
          {
            path: 'power-dialing',
            name: 'PDSessionsContainer',
            redirect: 'power-dialing/active',
            component: PDSessionsContainer,
            children: [
              {
                path: 'active',
                name: 'ActivePDSessions',
                component: ActivePDSessions,
                meta: {
                  requiresAuth: true,
                  showNav: true,
                  navTitle: 'Power Dialing',
                  hasSubMenu: true,
                  scrollView: false,
                },
              },
              {
                path: 'completed',
                name: 'CompletedPDSessions',
                component: CompletedPDSessions,
                meta: {
                  requiresAuth: true,
                  showNav: true,
                  navTitle: 'Power Dialing',
                  hasSubMenu: true,
                  scrollView: false,
                },
              },
            ],
          },
        ]),
    {
      path: 'call-summary',
      component: CallSummary,
      meta: {
        requiresAuth: true,
        adminOnly: true,
        showNav: true,
        navTitle: 'Call Summary',
        hasSubMenu: true,
        scrollView: true,
      },
    },
    {
      path: 'live-calls',
      name: 'LiveCalls',
      component: LiveCalls,
      meta: {
        requiresAuth: true,
        adminOnly: true,
        showNav: true,
        navTitle: 'Live Calls',
        hasSubMenu: true,
        scrollView: false,
      },
    },
    {
      path: 'call-summary/:id',
      name: 'UserCallSummary',
      component: UserCallSummary,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: 'Call Summary',
        hasSubMenu: true,
        scrollView: true,
      },
    },
    {
      path: 'call-details',
      name: 'CallDetails',
      component: CallDetails,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: isLiteMode ? 'Call History' : 'Call Details',
        hasSubMenu: true,
        scrollView: true,
      },
    },
    {
      path: 'call-details/:id',
      name: 'CallDetails',
      component: CallDetails,
      meta: {
        requiresAuth: true,
        showNav: true,
        navTitle: isLiteMode ? 'Call History' : 'Call Details',
        hasSubMenu: true,
        scrollView: true,
      },
    },
  ],
}

export default [
  {
    path: '/calls',
    ...callsRoutes,
  },
]
