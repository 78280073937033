// import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { Ripple } from 'vuetify/lib/directives'

import DialpadSlash from './icons/DialpadSlash'
import QuickActionMenu from './icons/QuickActionMenuIcon'
import SymboDrives from './icons/SymboDrivesIcon'
import OfficeBuildingPlus from './icons/OfficeBuildingPlus'
import SymboPrimaryIcon from './icons/SymboPrimaryIcon'
import PowerPhoneIcon from './icons/PowerPhoneIcon'
import MergeTagsIcon from './icons/MergeTags'
import SymboAi from './icons/SymboAi'
import Pipedrive from './icons/Pipedrive'
import GoHighLevel from './icons/GoHighLevel'

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
})

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#324499',
        // secondary: '#7a7d9c',
        accent: '#72CDE0',
        background: '#f3f5fe',
        'call-blue': '#46bcff',
        'email-orange': '#ff9054',
        'symbo-pink': '#ea446b',
        'symbo-yellow': '#FFF1D7',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      // override defaults
      sort: 'mdi-arrow-up-thin',

      // custom icons
      symboDrive: {
        component: SymboDrives,
      },
      officeBuildingPlus: {
        component: OfficeBuildingPlus,
      },
      symboPrimaryIcon: {
        component: SymboPrimaryIcon,
      },
      powerPhone: {
        component: PowerPhoneIcon,
      },
      quickActionMenu: {
        component: QuickActionMenu,
      },
      dialpadSlash: {
        component: DialpadSlash,
      },
      mergeTagsIcon: {
        component: MergeTagsIcon,
      },
      symboAi: {
        component: SymboAi,
      },
      pipedrive: {
        component: Pipedrive,
      },
      gohighlevel: {
        component: GoHighLevel,
      },
    },
  },
})
